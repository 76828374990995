import { Button, ChevronRightIcon, Heading, StepStatus } from "@allica/ui-react";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { Stage } from "../../BusinessRewards.types";

export const BusinessRewardsAdditionalPeople = () => {
  const { setCurrentStage, updateStepperConfig, stepperConfig } = useBusinessRewardsContext();

  const handleClick = () => {
    const newState = [{ stage: Stage.PEOPLE, value: { status: StepStatus.COMPLETE } }];
    // only set incomplete if the page has not yet been accessed/completed
    if (stepperConfig[Stage.DEPOSIT].status === StepStatus.INACTIVE) {
      newState.push({ stage: Stage.DEPOSIT, value: { status: StepStatus.INCOMPLETE } });
    }

    setCurrentStage(Stage.DEPOSIT);
    updateStepperConfig(newState);
  };

  return (
    <>
      <Heading color="$text-01" size="h1" as="h1" mb="24px" mt="64px">
        Additional people
      </Heading>

      <Button
        isLoading={false} // update with BE integration
        onClick={handleClick} // to progress whilst there is no form
        loadingText="Save and continue"
        spinnerPlacement="end"
        type="submit"
        float="right"
        mt="8rem"
        padding="2.4rem 3.2rem"
        rightIcon={<ChevronRightIcon />}
      >
        Save and continue
      </Button>
    </>
  );
};
