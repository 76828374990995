import { StepStatus } from "@allica/ui-react";
import { PropsWithChildren } from "react";
import { Navigate, Route } from "react-router-dom";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";

import { Stage, StageMVP } from "../BusinessRewards.types";
import { useBusinessRewardsContext } from "../context/BusinessRewardsContext";
import {
  BusinessRewardsApplicant,
  BusinessRewardsApplicationSummary,
  BusinessRewardsCompany,
  BusinessRewardsSignUp,
  BusinessRewardsVerify,
  BusinessRewardsDeposit,
  BusinessRewardsDocuments,
  BusinessRewardsAccountActivity,
  BusinessRewardsAdditionalPeople,
} from "../stages";

export const ProtectedRoute = ({
  stage,
  children,
}: PropsWithChildren<{ stage: StageMVP | Stage }>) => {
  const { stepperConfig, currentStage } = useBusinessRewardsContext();
  const { disabled, status } = stepperConfig[stage as StageMVP];

  if (disabled || status === StepStatus.INACTIVE) {
    if ((stage === StageMVP.SIGN_UP || stage === StageMVP.VERIFY) && !currentStage) {
      return <Navigate to="/business/current-account" replace />;
    }

    if (currentStage) {
      return <Navigate to={`/business/current-account/${currentStage}`} replace />;
    }
  }
  return <>{children}</>;
};

const BusinessRewardsRoutes = [
  <Route
    path={`${StageMVP.SIGN_UP}`}
    element={
      <ProtectedRoute stage={StageMVP.SIGN_UP}>
        <BusinessRewardsSignUp />
      </ProtectedRoute>
    }
    key={`${StageMVP.SIGN_UP}`}
  />,
  <Route
    path={`${StageMVP.VERIFY}`}
    element={
      <ProtectedRoute stage={StageMVP.VERIFY}>
        <BusinessRewardsVerify />
      </ProtectedRoute>
    }
    key={`${StageMVP.VERIFY}`}
  />,
  <Route
    path={`${StageMVP.BUSINESS}`}
    element={
      <ProtectedRoute stage={StageMVP.BUSINESS}>
        <BusinessRewardsCompany />
      </ProtectedRoute>
    }
    key={`${StageMVP.BUSINESS}`}
  />,
  <Route
    path={`${StageMVP.APPLICANT}`}
    element={
      <ProtectedRoute stage={StageMVP.APPLICANT}>
        <BusinessRewardsApplicant />
      </ProtectedRoute>
    }
    key={`${StageMVP.APPLICANT}`}
  />,
  <Route
    path={`${StageMVP.SUMMARY}`}
    element={
      <ProtectedRoute stage={StageMVP.SUMMARY}>
        <BusinessRewardsApplicationSummary />
      </ProtectedRoute>
    }
    key={`${StageMVP.SUMMARY}`}
  />,
];

if (isFeatureActive(FeatureFlag.BRA_PHASE_TWO)) {
  BusinessRewardsRoutes.push(
    <Route
      path={`${Stage.DEPOSIT}`}
      element={
        <ProtectedRoute stage={Stage.DEPOSIT}>
          <BusinessRewardsDeposit />
        </ProtectedRoute>
      }
      key={`${Stage.DEPOSIT}`}
    />,
    <Route
      path={`${Stage.ACCOUNT}`}
      element={
        <ProtectedRoute stage={Stage.ACCOUNT}>
          <BusinessRewardsAccountActivity />
        </ProtectedRoute>
      }
      key={`${Stage.ACCOUNT}`}
    />,
    <Route
      path={`${Stage.DOCUMENT}`}
      element={
        <ProtectedRoute stage={Stage.DOCUMENT}>
          <BusinessRewardsDocuments />
        </ProtectedRoute>
      }
      key={`${Stage.DOCUMENT}`}
    />,
  );
}

if (isFeatureActive(FeatureFlag.ADDITIONAL_PEOPLE)) {
  BusinessRewardsRoutes.push(
    <Route
      path={`${Stage.PEOPLE}`}
      element={
        <ProtectedRoute stage={Stage.PEOPLE}>
          <BusinessRewardsAdditionalPeople />
        </ProtectedRoute>
      }
      key={`${Stage.PEOPLE}`}
    />,
  );
}
export default BusinessRewardsRoutes;
