import { Button, SearchIcon } from "@allica/ui-react";
import { Box } from "@chakra-ui/react";
import { FormGroup } from "src/components/input-set/FormGroup";
import { CompanyDetailsProps } from "./CompanyDetails.types";
import { BaseInput } from "src/components/input/default/Input";
import { useFormContext } from "react-hook-form";
import { CompanyFormValues } from "../Company.types";
import { appInsights } from "src/core/app/ApplicationInsights";

const CompanyDetails = ({ setSearchCompanyPage }: CompanyDetailsProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CompanyFormValues>();

  const searchForAnotherCompany = () => {
    appInsights.trackEvent({ name: `business-select-another-company` });
    setSearchCompanyPage(true);
  };

  return (
    <>
      <Box mb={"3.2rem"}>
        <Button
          variant="text"
          onClick={searchForAnotherCompany}
          leftIcon={<SearchIcon boxSize="2.4rem" />}
        >
          Search for a different company
        </Button>
      </Box>
      <FormGroup label="Company name" mb="3.2rem" isRequired error={errors?.businessName?.message}>
        <BaseInput
          isDisabled
          isReadOnly
          isInvalid={!!errors?.businessName}
          sx={{ WebkitTextFillColor: "#1A3660" }}
          {...register("businessName", {
            required: "please provide a valid business name",
          })}
        />
      </FormGroup>
      <FormGroup label="Trading name" mb="3.2rem" error={errors?.businessTradingName?.message}>
        <BaseInput {...register("businessTradingName")} />
      </FormGroup>
      <FormGroup
        label="Company No."
        mb="3.2rem"
        error={errors?.companyHouseNumber?.message}
        isRequired
      >
        <BaseInput
          w="25.2rem"
          isDisabled
          isReadOnly
          isInvalid={!!errors?.companyHouseNumber}
          sx={{ WebkitTextFillColor: "#1A3660" }}
          {...register("companyHouseNumber", {
            required: "please provide a valid company number",
          })}
        />
      </FormGroup>
      <FormGroup label="Incorporated on" error={errors?.businessStartDate?.message} isRequired>
        <BaseInput
          w="25.2rem"
          isDisabled
          isReadOnly
          isInvalid={!!errors?.businessStartDate}
          sx={{ WebkitTextFillColor: "#1A3660" }}
          {...register("businessStartDate", {
            required: "please provide a valid date",
          })}
        />
      </FormGroup>
    </>
  );
};

export default CompanyDetails;
