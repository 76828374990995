/** a list of roles that deem an individual significant */
export const ROLES = ["director", "partner", "member"];
/** the minimum shareholding percentage held to determine significant status */
export const MIN_SHAREHOLDING = 25;
/** the minimum number of significant individuals required to show the additional people step */
export const MIN_SIGNIFICANT_INDIVIDUALS = 2;
/** the maximum number of significant individuals for which the additional people step will be shown */
export const MAX_SIGNIFICANT_INDIVIDUALS = 3;
/** the position (index) of which the 'Additional people' step should be within the `stepOrder`  */
export const PEOPLE_STEP_ORDER_INDEX = 4;
